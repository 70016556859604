import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkText from '../linkText/LinkText';

const terms =
  'https://firebasestorage.googleapis.com/v0/b/databox-60725.appspot.com/o/Condizioni%20Generali%20Bcode%20Piattaforma.pdf?alt=media&token=2c13de07-914b-4cf6-aef4-419983db3269';
const privacy =
  'https://firebasestorage.googleapis.com/v0/b/databox-60725.appspot.com/o/Informativa%20Privacy%20Bcode%20Piattaforma.pdf?alt=media&token=2d77d503-c601-4879-88f6-572465a7df1e';

const Item = ({ to, name }: { to: string; name: string }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-[56px] flex-row items-center justify-start gap-x-4 rounded-lg px-4 hover:bg-primary-100">
      <LinkText
        textColor="black"
        textColorHover="primary-500"
        textSize="text-body-regular-16"
        noUnderline
        newPage
        to={to}
      >
        {t(name)}
      </LinkText>
    </div>
  );
};

const LegalDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative flex items-center gap-1">
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={`absolute bottom-0 right-0 flex w-[210px] flex-col rounded-lg border border-grey-300 bg-white ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Item name="legal_dropdown.terms" to={terms} />
        <Item name="legal_dropdown.privacy" to={privacy} />
      </div>
      <p
        className="cursor-pointer text-body-medium-16 text-black underline decoration-black hover:text-black"
        onClick={() => setIsOpen(!isOpen)}
      >
        Legal
      </p>
    </div>
  );
};

export default LegalDropdown;
